import React from "react";

function Tokenomics() {
  return (
    <div className="roadmap">
      <div className="roadmap-title blink">TOKENOMICS</div>
      <div>
        <img
          style={{ width: "70%", marginTop: "0px" }}
          src="./assets/transparent_tokenomics.png"
          alt=""
        />
      </div>
    </div>
  );
}

export default Tokenomics;
