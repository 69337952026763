import React from "react";
import "./Teams.css";
function Teams() {
  return (
    <div className="teams">
      <div className="teams-title blink">Our Team</div>

      <div className="teams-body">
        <div>
          <div>
            <img className="teams-img" src="./assets/sneak1.gif" />
          </div>
          <div className="teams-name">DR. DINO</div>
          <div className="teams-desig">Project Manager</div>
        </div>
        <div>
          <div>
            <img className="teams-img" src="./assets/programer.png" />
          </div>
          <div className="teams-name">GRAYSON</div>
          <div className="teams-desig">Lead Developer</div>
        </div>
        <div>
          <div>
            <img className="teams-img" src="./assets/fletcher.png" />
          </div>
          <div className="teams-name">FLETCHER</div>
          <div className="teams-desig">Community Manager</div>
        </div>
        <div>
          <div>
            <img className="teams-img" src="./assets/artist.png" />
          </div>
          <div className="teams-name">HOWLPEN</div>
          <div className="teams-desig">Artist</div>
        </div>
      </div>
    </div>
  );
}

export default Teams;
