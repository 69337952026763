import React from "react";
import "./Section5.css";
function Section5() {
  return (
    <div className="join-community">
      <img className="bkg-join" src="./assets/bg-only_1.gif" alt="" />
      <div className="join-body">
        <div className="join">JOIN THE COMMUNITY</div>
        <div>
          <a href="https://discord.gg/dinolabs">
            <img
              className="join-discord"
              src="./assets/discord_white.png"
              alt=""
            />
          </a>
          <a href="https://twitter.com/DinoLabsNFT">
            <img
              className="join-discord"
              src="./assets/twitter_white.png"
              alt=""
            />
          </a>
        </div>
        <div className="dino">©2022 Dinolabs. All rights reserved.</div>
        {/* <div className="rights">©2022 Dinolabs. All rights reserved.</div> */}
      </div>
    </div>
  );
}

export default Section5;
