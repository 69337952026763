import React, { useState } from "react";
import "./Section2.css";

function Section2() {
  const [sneak, setSneak] = useState(1);
  return (
    <div className="sneakpeaks">
      <div className="left-half">
        <p className="about-us blink">ABOUT US</p>
        DinoLabs came into existence after Dr Dino, the only genetically
        enhanced Dino, made it out of statis after the experiments started by
        Prof. Maniac a few years back were abandoned and the specimens were left
        to die in their cryolizers. While, the whereabouts of Prof. Maniac still
        remains unknown, Dr. Dino decided to continue the legacy of his creater
        and started to experiment with Dinosaur Fossils and started genome
        editing by varying the concentration of $DLITE, the chemical which
        powers DinoLabs and assists the Dinos that make it out of statis to
        survive in this era of Earth.
      </div>
      <div className="right-half">
        <div>
          <img
            className="main__sneak"
            src="./assets/zenitzu.gif"
            // src={`./assets/sneak${sneak}.gif `}
            alt=""
          />
          <img
            onClick={() => setSneak(1)}
            className="main__sneak1"
            src="./assets/radioactive.gif"
            alt=""
          />
          <img
            onClick={() => setSneak(2)}
            className="main__sneak2"
            src="./assets/sneak2.gif"
            alt=""
          />
          <img
            onClick={() => setSneak(3)}
            className="main__sneak3"
            src="./assets/sneak3.gif"
            alt=""
          />
          <img
            onClick={() => setSneak(4)}
            className="main__sneak4"
            src="./assets/sneak4.gif"
            alt=""
          />
          <img
            onClick={() => setSneak(5)}
            className="main__sneak5"
            src="./assets/sneak5.gif"
            alt=""
          />
          <img
            onClick={() => setSneak(6)}
            className="main__sneak6"
            src="./assets/sneak6.gif"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Section2;
