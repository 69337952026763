import React from "react";
import Navbar from "../Navbar";
import "./Section1.css";
function Section1() {
  return (
    <div style={{ position: "relative" }}>
      <img className="landing-video" src="./assets/dino.gif" />

      <Navbar />
    </div>
  );
}

export default Section1;
