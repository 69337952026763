import { Link } from "@mui/material";
import React from "react";
import styled from "styled-components";
import "../App.css";

function Navbar() {
  return (
    <Container>
      <Wrap>
        <a href="/">
          <Logo className="main__logo" src={"/assets/logo_v8.png"} />
        </a>
      </Wrap>

      <div>
        <div className="landing-button-body">
          <a href="https://discord.gg/dinolabs">
            <div className="buttons">
              <img className="landing-button" src="./assets/button.png" />
              <div className="button-text">
                <img src="./assets/discord.png" alt="" />
              </div>
            </div>
          </a>
          <a href="https://twitter.com/DinoLabsNFT">
            <div className="buttons">
              <img className="landing-button" src="./assets/button.png" />
              <div className="button-text">
                <img src="./assets/twtter.png" alt="" />
              </div>
            </div>
          </a>
        </div>
      </div>
    </Container>
  );
}

export default Navbar;
const Container = styled.header`
  position: absolute;
  z-index: 10000;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justy-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const Wrap = styled.div`
  padding: 18px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 700px) {
    padding: 0px 0;
  }
`;

const Logo = styled.img`
  height: auto;
  width: 900px;
  @media (max-width: 1200px) {
    height: auto;
    width: 200px;
  }
`;
const Links = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin: 16px 0;
    color: #FFFFFF; 
    }
`;
const Other = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const SocialMedia = styled.div`
  position: relative;
  // background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  transition: 0.3s;

  img {
    height: auto;
    width: 38px;
    border-radius: 0px;
  }
  @media (max-width: 800px) {
    img {
      height: auto;
      width: 18px;
    }
  }

  @media (max-width: 460px) {
    img {
      height: auto;
      width: 18px;
    }
  }
`;

const ConnectButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  // padding: 15px 28px 14px;
  width: 144px;
  height: 52px;
  border-radius: 0px;
  border: 1px solid #ffffff;
  margin: 10px 10px;
  background: #ffffff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  outline-width: 0px;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    background: #9000ff !important;
    color: white;
  }
  // line-height: 111.5%;
  @media (max-width: 700px) {
    min-width: 144px;
    max-width: 128px;
    width: 100%;
    font-size: 14px;
  }
`;
