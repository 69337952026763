import React from "react";
import "./Section3.css";
function Section3() {
  return (
    <div className="roadmap">
      <img className="splash1" src="./assets/splash1.png" alt="" />
      <div className="roadmap-title blink">ROADMAP</div>
      <div className="phase1">
        <div className="phasesimg">
          <img src="./assets/meteor.gif" />
        </div>
        <div className="phase-text">
          <p className="phase1-title">Dinolabs Collection Drop</p>Finalizing the
          artwork, followed by launching our Twitter and Discord. Developing and
          releasing our website and publishing the Official Whitepaper and
          Tokenomics on Medium. Finally, a public sale of 360 genetically
          enhanced Dinos which will be listed on Magic Eden immediately after mint!
        </div>
      </div>

      <div className="phase1 inverted">
        <div className="phase-text">
          <p className="phase1-title">$DLITE token Launch</p>$DLITE will be the
          official token of our DinoLabs NFT which will be backed up by a
          liquidity pool. This token is what powers the entirety of DinoLabs and
          We plan to build an entire ecosystem around this token and also
          introduce multiple inter-utilities around it as the project proceeds
          further down the roadmap.
        </div>
        <div className="phasesimg">
          <img src="./assets/web-page.gif" />
        </div>
      </div>

      <img className="splash2" src="./assets/splash2.png" alt="" />
      <div className="phase1">
        <div className="phasesimg">
          <img src="./assets/phaseimg.gif" />
        </div>
        <div className="phase-text">
          <p className="phase1-title">Staking and Liquidity Pool</p>A staking
          mechanism will be set up, wherein our holders can stake our NFT’s to
          earn upto 7 $DLITE/day. A liquidity pool will be set up using the mint
          fund. This in turn will not only boost the ecosystem, but also assist
          our holders to earn a stable stream of passive income. To keep the
          cycle up and running, we will also be funding the pool with the
          revenue from the secondary sales.
        </div>
      </div>

      <div className="phase1 inverted">
        <div className="phase-text">
          <p className="phase1-title">DAO Formation</p>A DinoDao will be set up
          for our holders after mint. This DinoDAO and its members will assist
          in deciding the direction in which the project is headed by
          participating in DAO exclusive discussions and community decisions
          which will be implemented as per the majority. The DAO will also be
          replenished with 25% of our secondary sales.
        </div>
        <div className="phasesimg">
          <img src="./assets/roadmapimg.gif" />
        </div>
      </div>
      <img className="splash3" src="./assets/splash1.png" alt="" />

      <img className="splash2" src="./assets/splash2.png" alt="" />
      <div className="phase1">
        <div className="phasesimg">
          <img src="./assets/incubator.gif" />
        </div>
        <div className="phase-text">
          <p className="phase1-title">Inter-utilities</p>A rare collection of
          DinoLabs will be launched which can exclusively be minted using $DLITE
          tokens. These tokens bring value along with many other alpha whitelist
          opportunities. This is just the beginning, the benefits only increase
          from here on.
        </div>
      </div>
    </div>
  );
}

export default Section3;
