import "./App.css";
import Section1 from "./components/Section1/Section1";
import Section2 from "./components/Section2/Section2";
import Section3 from "./components/Section3/Section3";
import Section4 from "./components/Section4/Section4";
import Section5 from "./components/Section5/Section5";
import Teams from "./components/Team/Teams";
import Tokenomics from "./components/Tokenomics/Tokenomics";

function App() {
  return (
    <div className="App">
      <Section1 />
      <Section2 />
      <Section3 />
      <Tokenomics />
      <Teams />
      <Section4 />
      <Section5 />
    </div>
  );
}

export default App;
