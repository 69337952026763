import React, { useState } from "react";
import "./Section4.css";
function Section4() {
  const [answer, setAnswer] = useState(0);
  const [answer2, setAnswer2] = useState(0);
  const [answer3, setAnswer3] = useState(0);
  const [answer4, setAnswer4] = useState(0);
  const [answer5, setAnswer5] = useState(0);
  const [answer6, setAnswer6] = useState(0);
  const [answer7, setAnswer7] = useState(0);
  const [answer8, setAnswer8] = useState(0);
  return (
    <div className="faq">
      <div className="faq-title blink">FAQ</div>
      <div className="faq-body">
        <div className="faq-left">
          <div onClick={() => setAnswer(!answer)} className="question">
            <div>
              <div>What is DinoLabs?</div>
              <div>{!answer ? "+" : "-"}</div>
            </div>
            <div className={answer ? "answer" : "answer-hidden"}>
              DinoLabs are a collection of 360 genetically enhanced Dinos that
              made it out of the incubator to dominate the Solana Blockchain.
            </div>
          </div>

          <div onClick={() => setAnswer2(!answer2)} className="question">
            <div>
              <div>What is the collection size?</div>
              <div>{!answer2 ? "+" : "-"}</div>
            </div>
            <div className={answer2 ? "answer" : "answer-hidden"}>
              There will only ever be 360 Genesis Dinos.
            </div>
          </div>

          <div onClick={() => setAnswer3(!answer3)} className="question">
            <div>
              <div>What is the price of minting a Dino ?</div>
              <div>{!answer3 ? "+" : "-"}</div>
            </div>
            <div className={answer3 ? "answer" : "answer-hidden"}>
              1.5 SOL.
            </div>
          </div>

          <div onClick={() => setAnswer4(!answer4)} className="question">
            <div>
              <div>When is the Mint?</div>
              <div>{!answer4 ? "+" : "-"}</div>
            </div>
            <div className={answer4 ? "answer" : "answer-hidden"}>
              16th March, 5PM UTC for WL and 5:30PM UTC for public.
            </div>
          </div>

          <div onClick={() => setAnswer5(!answer5)} className="question">
            <div>
              <div>How do I get Whitelisted?</div>
              <div>{!answer5 ? "+" : "-"}</div>
            </div>
            <div className={answer5 ? "answer" : "answer-hidden"}>
              To know more about getting Whitelisted, join our Discord server.
              (discord.gg/dinolabs)
            </div>
          </div>

          <div onClick={() => setAnswer6(!answer6)} className="question">
            <div>
              <div>What is an $DLITE token?</div>
              <div>{!answer6 ? "+" : "-"}</div>
            </div>
            <div className={answer6 ? "answer" : "answer-hidden"}>
              $DLITE token is the basic genome block of our Dinos and helps to
              keep our Dinos alive. It also serves as means to keep the
              community running efficiently.
            </div>
          </div>

          {/* <div onClick={() => setAnswer7(!answer7)} className="question">
            <div>
              <div>
                Are we going to have an LP where $DLITE will be tradeable for
                SOL?
              </div>
              <div>{!answer7 ? "+" : "-"}</div>
            </div>
            <div className={answer7 ? "answer" : "answer-hidden"}>
              Yes we will. You will be able to buy/sell your $DLITE on Raydium.
            </div>
          </div> */}

          <div onClick={() => setAnswer8(!answer8)} className="question">
            <div>
              <div>Will the project be listed on secondary markets?</div>
              <div>{!answer8 ? "+" : "-"}</div>
            </div>
            <div className={answer8 ? "answer" : "answer-hidden"}>
              Yes, our project will be listed on MagicEden and Solanart
              immediately after mint!
            </div>
          </div>
        </div>
        <div className="faq-right">
          <img className="faq-img" src="./assets/wtf_bro_meme.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Section4;
